<div
    ngModelGroup="app-registration"
    id="registrationForm"
    [ngClass]="isEditMode ? 'editing' : isCreateMode ? 'creating' : 'viewing'"
>
    <!--- CREATE / EDIT --->
    <ng-container *ngIf="(isCreateMode || isEditMode) && !isRetired">
        <div class="form-wrapper">
            <!--- SECTION 1 --->
            <div class="form-entry">
                <!--- supported locales --->
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>{{ 'registration.supported_locales' | translate }}</mat-label>
                    <mat-select
                        id="supportedLocale"
                        name="supportedLocale"
                        #supportedLocale="ngModel"
                        class="input-entry create-select"
                        [ngClass]="{
                            failure: supportedLocale.invalid && (supportedLocale.dirty || supportedLocale.touched)
                        }"
                        [(ngModel)]="multiLocaleConfig.supportedLocales"
                        (selectionChange)="handleSelectionChange()"
                        placeholder="{{ 'registration.create.placeholder.supported_locales' | translate }}"
                        data-automation="locale"
                        required
                        multiple
                    >
                        <mat-option
                            *ngFor="let locale of locales"
                            [disabled]="limitLocaleSelectList(5, locale)"
                            [value]="locale"
                            >{{ locale.displayLabel }}</mat-option
                        >
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="form-entry">
                <!--- default locale --->
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>{{ 'registration.locale' | translate }}</mat-label>
                    <mat-select
                        id="defaultLocale"
                        name="defaultLocale"
                        #defaultLocale="ngModel"
                        class="input-entry create-select"
                        [ngClass]="{ failure: defaultLocale.invalid && (defaultLocale.dirty || defaultLocale.touched) }"
                        [(ngModel)]="registration.defaultLocale"
                        placeholder="{{ 'registration.create.placeholder.locale_selector' | translate }}"
                        data-automation="locale"
                        required
                    >
                        <mat-option
                            *ngFor="let locale of multiLocaleConfig.supportedLocales"
                            [value]="locale.localeName"
                            >{{ locale.displayLabel }}</mat-option
                        >
                    </mat-select>
                    <mat-error *ngIf="defaultLocale.invalid">
                        <mat-error *ngIf="defaultLocale.errors.pattern">
                            {{ 'registration.validation.required' | translate }}
                        </mat-error>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="form-entry">
                <!--- timezone --->
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>{{ 'registration.timezone' | translate }}</mat-label>
                    <mat-select
                        id="timezone"
                        name="timezone"
                        #timezone="ngModel"
                        class="input-entry create-select"
                        [ngClass]="{ failure: timezone.invalid && (timezone.dirty || timezone.touched) }"
                        [(ngModel)]="registration.timezone"
                        data-automation="timezone"
                        required
                        placeholder="{{ 'registration.create.placeholder.timezone_selector' | translate }}"
                    >
                        <mat-option *ngFor="let timezone of timezones" [value]="timezone.timezoneName">{{
                            timezone.displayLabel
                        }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="timezone.invalid">
                        <mat-error *ngIf="timezone.errors.pattern">
                            {{ 'registration.validation.required' | translate }}
                        </mat-error>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="form-entry" *ngIf="isEditMode || isCreateMode">
                <!--- reg_name --->
                <multi-locale-input
                    #displayLabels="ngModel"
                    name="displayLabels"
                    [(ngModel)]="registration.displayLabels"
                    [inputLabel]="nameLabel"
                    [locales]="multiLocaleConfig.supportedLocales"
                    [displayLabelKey]="multiLocaleConfig.displayLabelKey"
                    [localeKey]="multiLocaleConfig.localeKey"
                    [defaultLocale]="multiLocaleConfig.defaultLocale"
                    [placeholderText]="namePlaceholder"
                    [required]="true"
                    [id]="'registration_display_labels'"
                    data-automation="regName"
                    (ngModelChange)="onChangeName($event)"
                    [maxLength]="200"
                    matTooltip="{{ registrationName }}"
                    matTooltipPosition="above"
                >
                </multi-locale-input>
            </div>

            <div class="form-entry">
                <!--- reg_id --->
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>{{ 'registration.registration_id' | translate }}</mat-label>
                    <input
                        matInput
                        id="regId"
                        type="text"
                        #regId="ngModel"
                        class="input-entry"
                        [ngClass]="{ failure: regId.invalid && (regId.dirty || regId.touched) }"
                        name="regId"
                        placeholder="{{ 'registration.create.placeholder.registration_id' | translate }}"
                        maxlength="80"
                        [(ngModel)]="registration.regId"
                        data-automation="regId"
                        data-url-persist
                    />
                    <mat-error *ngIf="regId.invalid">
                        <mat-error *ngIf="regId.errors.required">
                            <span class="input-entry">{{ 'registration.validation.required' | translate }}</span>
                        </mat-error>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="form-entry">
                <!--- crm_id --->
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>{{ 'registration.crm_id' | translate }}</mat-label>
                    <input
                        matInput
                        id="crmId"
                        type="text"
                        #crmId="ngModel"
                        class="input-entry"
                        [ngClass]="{ failure: crmId.invalid && (crmId.dirty || crmId.touched) }"
                        name="crmId"
                        placeholder="{{ 'registration.create.placeholder.crm_id' | translate }}"
                        maxlength="80"
                        [pattern]="ecrmPattern"
                        [(ngModel)]="registration.crmId"
                        [ngModelOptions]="{ updateOn: 'blur' }"
                        data-automation="crmId"
                        data-url-persist
                    />
                    <mat-error *ngIf="crmId.invalid">
                        <mat-error *ngIf="crmId.errors.required">
                            <span class="input-entry">{{ 'registration.validation.required' | translate }}</span>
                        </mat-error>
                        <mat-error *ngIf="crmId.errors.pattern">
                            <span class="input-entry">{{ 'registration.validation.ecrmId' | translate }}</span>
                        </mat-error>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="form-entry">
                <!--- product --->
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>{{ 'registration.product' | translate }}</mat-label>
                    <input type="text"
                           placeholder="{{ 'registration.create.placeholder.product_selector' | translate }}"
                           matInput
                           name="productId"
                           data-automation="productSelector"
                           id="product"
                           #productId="ngModel"
                           class="input-entry create-select"
                           [class.loading-field]="appData.loadingProducts"
                           [ngClass]="{ failure: productId.invalid && (productId.dirty || productId.touched) }"
                           [(ngModel)]="registration.productId"
                           required
                           [matAutocomplete]="auto">
                    <mat-autocomplete
                        #auto=matAutocomplete
                        [class.loading-field]="appData.loadingProducts"
                        (optionSelected)='onProductChange($event.option.value)'
                        [displayWith]="displayFn"
                    >
                        <cdk-virtual-scroll-viewport
                                itemSize="48"
                                id="product-panel"
                                [style.height.px]="productSelectHeight"
                                minBufferPx="400"
                                maxBufferPx="400">
                            <mat-option *cdkVirtualFor="let product of filteredProducts" [value]="product">
                                {{ product.displayLabel }}
                            </mat-option>
                        </cdk-virtual-scroll-viewport>
                    </mat-autocomplete>
                    <mat-error *ngIf="productId.invalid">
                        <mat-error *ngIf="productId.errors.required">
                            {{ 'registration.validation.required' | translate }}
                        </mat-error>
                    </mat-error>
                    <mat-hint *ngIf="appData.loadingProducts" align="end">{{
                        'registration.loading' | translate
                    }}</mat-hint>
                    <mat-spinner *ngIf="appData.loadingProducts" matSuffix diameter="23"></mat-spinner>
                </mat-form-field>
            </div>

            <div class="form-entry">
                <lib-ngx-deeplinker [config]="marketDeeplinkerConfig" id="product_deeplink"></lib-ngx-deeplinker>
            </div>

            <div class="form-entry">
                <!--- status --->
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>{{ 'registration.status' | translate }}</mat-label>
                    <mat-select
                        id="status"
                        name="status"
                        #status="ngModel"
                        class="input-entry create-select"
                        [ngClass]="{ failure: status.invalid && (status.dirty || status.touched) }"
                        [(ngModel)]="registration.status"
                        data-automation="status"
                        required
                    >
                        <mat-option *ngFor="let status of statuses" [value]="status.code">{{
                            status.displayLabel
                        }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="status.invalid">
                        <mat-error *ngIf="status.errors.pattern">
                            {{ 'registration.validation.required' | translate }}
                        </mat-error>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="form-entry" *ngIf="isCreateMode">
                <!--- site --->
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>{{ 'registration.site' | translate }}</mat-label>
                    <mat-select
                        id="siteSelector"
                        name="site"
                        #site="ngModel"
                        class="input-entry create-select"
                        [class.loading-field]="appData.loadingSites"
                        [ngClass]="{ failure: site.invalid && (site.dirty || site.touched) }"
                        [(ngModel)]="selectedSite"
                        (selectionChange)="onSiteChange($event.value)"
                        [disabled]="!org"
                        required
                        data-automation="siteSelector"
                        data-url-persist
                        placeholder="{{
                            (appData.sites.length > 0
                                ? 'registration.create.placeholder.site_selector'
                                : 'registration.create.placeholder.no_sites'
                            ) | translate
                        }}"
                        matTooltip="{{ selectedSite_label }}"
                        matTooltipPosition="above"
                    >
                        <mat-option
                            *ngFor="let site of appData.sites"
                            [value]="site.id"
                            id="{{ site.id }}"
                            matTooltip="{{ site.displayLabel }}"
                            matTooltipPosition="above"
                            >{{ site.displayLabel }}</mat-option
                        >
                    </mat-select>
                    <mat-error *ngIf="site.invalid">
                        <mat-error *ngIf="site.errors.required || site.errors.selectValid">
                            <span>{{ 'registration.validation.required' | translate }}</span>
                        </mat-error>
                    </mat-error>
                    <mat-hint *ngIf="appData.loadingSites" align="end">{{
                        'registration.loading' | translate
                    }}</mat-hint>
                    <mat-spinner *ngIf="appData.loadingSites" matSuffix diameter="23"></mat-spinner>
                </mat-form-field>
            </div>

            <div class="form-entry">
                <!--- sdp --->
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>{{ 'registration.sdp' | translate }}</mat-label>
                    <mat-select
                        id="sdp"
                        name="sdpId"
                        #sdpId="ngModel"
                        class="input-entry create-select"
                        [class.loading-field]="appData.loadingSDPs"
                        [ngClass]="{ failure: sdpId.invalid && (sdpId.dirty || sdpId.touched) }"
                        [(ngModel)]="registration.sdpId"
                        [disabled]="!selectedSite || isEditMode"
                        data-automation="sdpSelector"
                        data-url-persist
                        [required]="!registration.fullReg"
                        placeholder="{{
                            (filterSDPsByActiveSA(sdps).length > 0
                                ? 'registration.create.placeholder.sdp_selector'
                                : 'registration.create.placeholder.no_sdps'
                            ) | translate
                        }}"
                        matTooltip="{{ sdp_changed || !clone ? sdp_label_to_display : getSDPDisplayLabel() }}"
                        matTooltipPosition="above"
                    >
                        <ng-container *ngIf="isCreateMode">
                            <mat-optgroup *ngFor="let sdp of filterSDPsByActiveSA(sdps)" [label]="sdp.displayLabel">
                                <ng-container *ngFor="let sa of sdp.serviceAccounts"
                                    ><mat-option
                                        *ngIf="sa.status === 'ACTIVE'"
                                        [value]="sdp.id"
                                        id="{{ sdp.id }}_{{ sa.id }}"
                                        (click)="onSdpChange(sdp.id + '_' + sa.id)"
                                        matTooltip="{{ sa.displayLabel }}"
                                        matTooltipPosition="above"
                                        >{{ sa.displayLabel }}</mat-option
                                    >
                                </ng-container>
                            </mat-optgroup>
                        </ng-container>

                        <ng-container *ngIf="isEditMode && selectedSDP">
                            <mat-option
                                [value]="selectedSDP.id"
                                matTooltip="{{ selectedSDP.displayLabel }}"
                                matTooltipPosition="above"
                            >
                                {{ selectedSDP.displayLabel }}
                            </mat-option>
                        </ng-container>
                    </mat-select>
                    <mat-error *ngIf="sdpId.invalid">
                        <mat-error *ngIf="sdpId.errors.required">
                            {{ 'registration.validation.required' | translate }}
                        </mat-error>
                    </mat-error>
                    <mat-hint *ngIf="appData.loadingSDPs" align="end">{{
                        'registration.loading' | translate
                    }}</mat-hint>
                    <mat-spinner *ngIf="appData.loadingSDPs" matSuffix diameter="23"></mat-spinner>
                </mat-form-field>
            </div>

            <div class="form-entry" *ngIf="isEditMode || isCreateMode">
                <!--- description --->
                <multi-locale-input
                    #descriptions="ngModel"
                    name="descriptions"
                    [(ngModel)]="registration.descriptions"
                    [inputLabel]="descriptionLabel"
                    [locales]="multiLocaleConfig.supportedLocales"
                    [displayLabelKey]="multiLocaleConfig.displayLabelKey"
                    [localeKey]="multiLocaleConfig.localeKey"
                    [defaultLocale]="multiLocaleConfig.defaultLocale"
                    [placeholderText]="descriptionPlaceholder"
                    [required]="false"
                    [maxLength]="100"
                    [id]="'registration_descriptions'"
                >
                </multi-locale-input>
            </div>
        </div>
        <h4
            *ngIf="isCreateMode"
            class="form-header expandable-header"
            (click)="toggleFullRegistration()"
            data-automation="toggleFullReg"
        >
            <span>
                {{ 'registration.create.reg_dispatch_fields' | translate }}
            </span>
            <mat-icon *ngIf="!registration.fullReg">expand_more</mat-icon>
            <mat-icon *ngIf="registration.fullReg">expand_less</mat-icon>
            <input name="isFullReg" id="isFullReg" type="hidden" [(ngModel)]="registration.fullReg" data-url-persist />
        </h4>

        <ng-container *ngIf="registration.fullReg">
            <div class="form-wrapper">
                <!--- Section 2 --->

                <div class="form-entry">
                    <!--- Asset --->
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'registration.asset' | translate }}</mat-label>
                        <mat-select
                            id="asset"
                            name="assetId"
                            #assetId="ngModel"
                            class="input-entry create-select"
                            [class.loading-field]="appData.loadingAssets"
                            [ngClass]="{ failure: assetId.invalid && (assetId.dirty || assetId.touched) }"
                            [(ngModel)]="registration.flexibleAssetId"
                            [disabled]="!selectedSite"
                            [required]="registration.fullReg"
                            data-automation="assetSelector"
                            data-url-persist
                            placeholder="{{
                                (appData.flexibleAssets && appData.flexibleAssets.length > 0
                                    ? 'registration.create.placeholder.asset_selector'
                                    : 'registration.create.placeholder.no_assets'
                                ) | translate
                            }}"
                        >
                            <mat-option *ngFor="let asset of appData.flexibleAssets" [value]="asset.id" [id]="asset.id"
                                >{{ asset.displayLabel }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="assetId.invalid">
                            <mat-error *ngIf="assetId.errors.required || assetId.errors.selectValid">
                                {{ 'registration.validation.required' | translate }}
                            </mat-error>
                        </mat-error>
                        <mat-hint *ngIf="appData.loadingAssets" align="end">{{
                            'registration.loading' | translate
                        }}</mat-hint>
                        <mat-spinner *ngIf="appData.loadingAssets" matSuffix diameter="23"></mat-spinner>
                    </mat-form-field>
                </div>

                <div class="form-entry">
                    <!--- Flexible Asset Deeplink --->
                    <lib-ngx-deeplinker [config]="assetDeeplinkerConfig" id="asset_deeplink"></lib-ngx-deeplinker>
                </div>

                <div class="form-entry">
                    <!--- Portfolio --->
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'registration.portfolio' | translate }}</mat-label>
                        <mat-select
                            id="portfolio"
                            name="portfolioId"
                            #portfolioId="ngModel"
                            class="input-entry create-select"
                            [class.loading-field]="appData.loadingPortfolios"
                            [ngClass]="{ failure: portfolioId.invalid && (portfolioId.dirty || portfolioId.touched) }"
                            [(ngModel)]="registration.portfolioId"
                            [disabled]="!registration.productId"
                            data-automation="portfolioSelector"
                            data-url-persist
                            placeholder="{{
                                (appData.portfolios && appData.portfolios.length > 0
                                    ? 'registration.create.placeholder.portfolio_selector'
                                    : 'registration.create.placeholder.no_portfolios'
                                ) | translate
                            }}"
                        >
                            <mat-option>{{ 'registration.edit.unassign_portfolio' | translate }}</mat-option>
                            <mat-option
                                *ngFor="let portfolio of appData.portfolios"
                                [value]="portfolio.id"
                                [id]="portfolio.id"
                                >{{ portfolio.displayLabel }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="portfolioId.invalid">
                            <mat-error *ngIf="portfolioId.errors.required || portfolioId.errors.selectValid">
                                {{ 'registration.validation.required' | translate }}
                            </mat-error>
                        </mat-error>
                        <mat-hint *ngIf="appData.loadingPortfolios" align="end">{{
                            'registration.loading' | translate
                        }}</mat-hint>
                        <mat-spinner *ngIf="appData.loadingPortfolios" matSuffix diameter="23"></mat-spinner>
                    </mat-form-field>
                </div>

                <div class="form-entry site-edit" *ngIf="isEditMode">
                    <!--- Site --->
                    <mat-label data-automation="site" class="info-title"
                        >{{ 'registration.site' | translate }}:</mat-label
                    >
                    <a
                        *ngIf="ownerId"
                        href="{{ getCustomerAdminUrl(ownerId) }}"
                        data-automation="siteDisplay"
                        target="_blank"
                        class="new-tab-link truncate"
                        [matTooltip]="site?.display_label"
                    >
                        {{ site?.display_label }}
                        <mat-icon>open_in_new</mat-icon>
                    </a>
                    <mat-label *ngIf="!ownerId" class="info-value info-placeHolder" data-automation="siteDisplay">
                        {{ 'registration.view.no_site' | translate }}
                    </mat-label>
                </div>

                <div class="form-entry">
                    <!--- Registration Type --->
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'registration.registration_type' | translate }}</mat-label>
                        <mat-select
                            id="registrationType"
                            name="registrationType"
                            #registrationType="ngModel"
                            class="input-entry create-select"
                            [ngClass]="{ failure: registrationType.dirty || registrationType.touched }"
                            [(ngModel)]="registration.registrationType"
                            data-automation="registrationType"
                            data-url-persist
                            required
                            placeholder="{{
                                (registrationTypes && registrationTypes.length > 0
                                    ? 'registration.create.placeholder.registration_type_selector'
                                    : 'registration.create.placeholder.no_registration_types'
                                ) | translate
                            }}"
                        >
                            <mat-option *ngFor="let type of registrationTypes" [value]="type.code" [id]="type.code"
                                >{{ type.displayLabel }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="registrationType.invalid">
                            <mat-error *ngIf="registrationType.errors.required || registrationType.errors.selectValid">
                                {{ 'registration.validation.required' | translate }}
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="form-entry">
                    <!--- Control Type --->
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'registration.control_type' | translate }}</mat-label>
                        <mat-select
                            id="controlType"
                            name="controlTypeId"
                            #controlTypeId="ngModel"
                            class="input-entry create-select"
                            [class.loading-field]="appData.loadingControlTypes"
                            [ngClass]="{
                                failure: controlTypeId.invalid && (controlTypeId.dirty || controlTypeId.touched)
                            }"
                            [(ngModel)]="registration.controlType"
                            data-automation="controlTypeSelector"
                            data-url-persist
                            required
                            placeholder="{{
                                (appData.controlTypes && appData.controlTypes.length > 0
                                    ? 'registration.create.placeholder.control_type_selector'
                                    : 'registration.create.placeholder.no_control_types'
                                ) | translate
                            }}"
                        >
                            <mat-option
                                *ngFor="let controlType of appData.controlTypes"
                                [value]="controlType.code"
                                [id]="controlType.code"
                                >{{ controlType.displayLabel }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="controlTypeId.invalid">
                            <mat-error *ngIf="controlTypeId.errors.required">
                                {{ 'registration.validation.required' | translate }}
                            </mat-error>
                        </mat-error>
                        <mat-hint *ngIf="appData.loadingControlTypes" align="end">{{
                            'registration.loading' | translate
                        }}</mat-hint>
                        <mat-spinner *ngIf="appData.loadingControlTypes" matSuffix diameter="23"></mat-spinner>
                    </mat-form-field>
                </div>

                <div class="form-entry">
                    <!--- Registration external reference Id--->
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'registration.registration_external_reference_id' | translate }}</mat-label>
                        <input
                            matInput
                            name="registrationExternalReferenceId"
                            id="registrationExternalReferenceId"
                            #registrationExternalReferenceId="ngModel"
                            type="text"
                            class="input-entry"
                            [ngClass]="{
                                failure:
                                    registrationExternalReferenceId.invalid &&
                                    (registrationExternalReferenceId.dirty || registrationExternalReferenceId.touched)
                            }"
                            placeholder="{{
                                'registration.create.placeholder.registration_external_reference_id' | translate
                            }}"
                            maxlength="80"
                            [(ngModel)]="registration.registrationExternalReferenceId"
                            data-automation="registrationExternalReferenceId"
                            data-url-persist
                        />
                        <mat-error *ngIf="registrationExternalReferenceId.invalid">
                            <mat-error *ngIf="registrationExternalReferenceId.errors.required">
                                <span>{{ 'registration.validation.required' | translate }}</span>
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="form-entry">
                    <!--- Registration location Id--->
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'registration.registration_location_id' | translate }}</mat-label>
                        <input
                            matInput
                            name="registrationLocationId"
                            id="registrationLocationId"
                            #registrationLocationId="ngModel"
                            type="text"
                            class="input-entry"
                            [ngClass]="{
                                failure:
                                    registrationLocationId.invalid &&
                                    (registrationLocationId.dirty || registrationLocationId.touched)
                            }"
                            placeholder="{{ 'registration.create.placeholder.registration_location_id' | translate }}"
                            maxlength="80"
                            [(ngModel)]="registration.marketConf.locationId"
                            data-automation="registrationLocationId"
                            data-url-persist
                        />
                        <mat-error *ngIf="registrationLocationId.invalid">
                            <mat-error *ngIf="registrationLocationId.errors.required">
                                <span>{{ 'registration.validation.required' | translate }}</span>
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>

                <ng-container
                    ngModelGroup="scheduleStartEndDates"
                    #scheduleStartEndDates="ngModelGroup"
                    startEndDateValidator
                    [startControl]="effectiveDttm"
                    [endControl]="terminationDttm"
                >
                    <!--- Effective Date --->
                    <div class="form-entry">
                        <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label>{{ 'registration.effective_date' | translate }}</mat-label>
                            <input
                                matInput
                                [matDatepicker]="effectiveDate"
                                [(ngModel)]="registration.effectiveDttm"
                                #effectiveDttm="ngModel"
                                required
                                name="effectiveDttm"
                                id="effectiveDttm"
                                placeholder="{{ 'registration.create.placeholder.select_date' | translate }}"
                                data-automation="effectiveDate"
                                data-url-persist
                            />
                            <mat-datepicker-toggle matSuffix [for]="effectiveDate">
                                <mat-icon matDatepickerToggleIcon
                                    ><img
                                        alt="calendar"
                                        src="../../assets/svg/calendar-new.svg"
                                        width="20"
                                        data-automation="effectiveDate_icon"
                                /></mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #effectiveDate startView="month"></mat-datepicker>
                            <mat-error *ngIf="effectiveDttm.invalid">
                                <mat-error *ngIf="effectiveDttm.errors.required">
                                    {{ 'registration.validation.required' | translate }}
                                </mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="form-entry">
                        <!--- Termination Date --->
                        <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label>{{ 'registration.termination_date' | translate }}</mat-label>
                            <input
                                matInput
                                [matDatepicker]="terminationDate"
                                [(ngModel)]="registration.terminationDttm"
                                #terminationDttm="ngModel"
                                name="terminationDttm"
                                id="terminationDttm"
                                data-url-persist
                                placeholder="{{ 'registration.create.placeholder.select_date' | translate }}"
                                data-automation="terminationDate"
                            />
                            <mat-datepicker-toggle matSuffix [for]="terminationDate">
                                <mat-icon matDatepickerToggleIcon
                                    ><img
                                        alt="calendar"
                                        src="../../assets/svg/calendar-new.svg"
                                        data-automation="terminationDate_icon"
                                        width="20"
                                /></mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #terminationDate startView="month"></mat-datepicker>
                        </mat-form-field>
                    </div>

                    <mat-error class="group-error" *ngIf="scheduleStartEndDates.invalid">
                        <mat-error
                            *ngIf="scheduleStartEndDates.errors && scheduleStartEndDates.errors.startEndDateValid"
                        >
                            <span class="input-title"></span>
                            <span class="input-entry" data-automation="validationMessage">{{
                                'registration.validation.start_end_date' | translate
                            }}</span>
                        </mat-error>
                    </mat-error>
                </ng-container>

                <ng-container
                    ngModelGroup="MinMaxValue"
                    #MinMaxValue="ngModelGroup"
                    minMaxValidator
                    [minControl]="minimumValue"
                    [maxControl]="maximumValue"
                >
                    <!--- Minimum Value --->
                    <div class="form-entry">
                        <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label>{{ 'registration.minimum_value' | translate }}</mat-label>
                            <input
                                matInput
                                id="minimumValue"
                                type="number"
                                step=".01"
                                #minimumValue="ngModel"
                                class="input-entry"
                                [ngClass]="{
                                    failure: minimumValue.invalid && (minimumValue.dirty || minimumValue.touched)
                                }"
                                name="minimumValue"
                                placeholder="0"
                                maxlength="80"
                                min="0"
                                [(ngModel)]="registration.minimumValue"
                                data-automation="minimumValue"
                                data-url-persist
                                required
                            />
                            <mat-error *ngIf="minimumValue.invalid">
                                <mat-error *ngIf="minimumValue.errors.required">
                                    <span class="input-entry">{{
                                        'registration.validation.required' | translate
                                    }}</span>
                                </mat-error>
                                <mat-error *ngIf="minimumValue.errors.min">
                                    <span class="input-entry">{{
                                        'registration.validation.equal_or_greater_than_zero' | translate
                                    }}</span>
                                </mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <!--- Maximum Value --->
                    <div class="form-entry label-info">
                        <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label>{{ 'registration.maximum_value' | translate }}</mat-label>
                            <input
                                matInput
                                id="maximumValue"
                                type="number"
                                step=".01"
                                #maximumValue="ngModel"
                                class="input-entry"
                                [ngClass]="{
                                    failure: maximumValue.invalid && (maximumValue.dirty || maximumValue.touched)
                                }"
                                name="maximumValue"
                                placeholder="0"
                                maxlength="80"
                                min="0"
                                (keydown)="onKeydown($event)"
                                [(ngModel)]="registration.maximumValue"
                                data-automation="maximumValue"
                                data-url-persist
                                required
                            />
                            <mat-icon
                                svgIcon="info"
                                data-automation="maximumValueIcon"
                                matSuffix
                                class="tooltip-icon"
                                matTooltip="{{ 'registration.tooltip.maximum_value' | translate }}"
                            ></mat-icon>
                            <mat-error *ngIf="maximumValue.invalid">
                                <mat-error *ngIf="maximumValue.errors.required">
                                    <span class="input-entry">{{
                                        'registration.validation.required' | translate
                                    }}</span>
                                </mat-error>
                                <mat-error *ngIf="maximumValue.errors.min">
                                    <span class="input-entry">{{
                                        'registration.validation.equal_or_greater_than_zero' | translate
                                    }}</span>
                                </mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <mat-error class="mat-error" *ngIf="MinMaxValue.invalid">
                        <mat-error class="group-error" *ngIf="MinMaxValue.errors && MinMaxValue.errors.minMaxValid">
                            {{ 'registration.validation.max_min_val' | translate }}
                        </mat-error>
                    </mat-error>
                </ng-container>

                <div class="form-entry">
                    <!--- Control Timeout Override --->
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'registration.control_timeout_override' | translate }}</mat-label>
                        <input
                            matInput
                            id="controlTimeoutOverride"
                            type="number"
                            step="1"
                            #controlTimeoutOverride="ngModel"
                            class="input-entry"
                            [ngClass]="{
                                failure:
                                    controlTimeoutOverride.invalid &&
                                    (controlTimeoutOverride.dirty || controlTimeoutOverride.touched)
                            }"
                            name="controlTimeoutOverride"
                            placeholder="0"
                            maxlength="16"
                            min="0"
                            [(ngModel)]="registration.controlTimeoutOverride"
                            data-automation="controlTimeoutOverride"
                            data-url-persist
                        />
                        <mat-error *ngIf="controlTimeoutOverride.invalid">
                            <mat-error *ngIf="controlTimeoutOverride.errors.min">
                                <span class="input-entry">{{
                                    'registration.validation.equal_or_greater_than_zero' | translate
                                }}</span>
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="form-entry">
                    <!--- Control Time Override --->
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'registration.control_time_override' | translate }}</mat-label>
                        <input
                            matInput
                            id="controlTimeOverride"
                            type="number"
                            step="1"
                            #controlTimeOverride="ngModel"
                            class="input-entry"
                            [ngClass]="{
                                failure:
                                    controlTimeOverride.invalid &&
                                    (controlTimeOverride.dirty || controlTimeOverride.touched)
                            }"
                            name="controlTimeOverride"
                            placeholder="0"
                            maxlength="16"
                            min="0"
                            [(ngModel)]="registration.controlTimeOverride"
                            data-automation="controlTimeOverride"
                            data-url-persist
                        />
                        <mat-error *ngIf="controlTimeOverride.invalid">
                            <mat-error *ngIf="controlTimeOverride.errors.min">
                                <span class="input-entry">{{
                                    'registration.validation.equal_or_greater_than_zero' | translate
                                }}</span>
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="form-entry">
                    <!--- Restore Time Override --->
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label data-automation="restoreTimeOverride">{{
                            'registration.restore_time_override' | translate
                        }}</mat-label>
                        <input
                            matInput
                            id="restoreTimeOverride"
                            type="number"
                            step="1"
                            #restoreTimeOverride="ngModel"
                            class="input-entry"
                            [ngClass]="{
                                failure:
                                    restoreTimeOverride.invalid &&
                                    (restoreTimeOverride.dirty || restoreTimeOverride.touched)
                            }"
                            name="restoreTimeOverride"
                            placeholder="0"
                            maxlength="16"
                            [(ngModel)]="registration.restoreTimeOverride"
                            data-automation="restoreTimeOverrideValue"
                            data-url-persist
                            (keydown)="onKeydownNegativeValues($event)"
                        />
                    </mat-form-field>
                </div>

                <div class="form-entry">
                    <!--- Initial Notification Time Override --->
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label data-automation="initialNotifTimeOverride">{{
                            'registration.initial_notif_time_override' | translate
                        }}</mat-label>
                        <input
                            matInput
                            id="initialNotifTimeOverride"
                            type="number"
                            step="1"
                            #initialNotifTimeOverride="ngModel"
                            class="input-entry"
                            [ngClass]="{
                                failure:
                                    initialNotifTimeOverride.invalid &&
                                    (initialNotifTimeOverride.dirty || initialNotifTimeOverride.touched)
                            }"
                            name="initialNotifTimeOverride"
                            placeholder="0"
                            maxlength="16"
                            [(ngModel)]="registration.initialNotifTimeOverride"
                            data-automation="initialNotifTimeOverrideValue"
                            data-url-persist
                            (keydown)="onKeydownNegativeValues($event)"
                        />
                    </mat-form-field>
                </div>

                <div class="form-entry">
                    <!--- Restore Notification Time Override --->
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label data-automation="restoreNotifTimeOverride">{{
                            'registration.restore_notif_time_override' | translate
                        }}</mat-label>
                        <input
                            matInput
                            id="restoreNotifTimeOverride"
                            type="number"
                            step="1"
                            #restoreNotifTimeOverride="ngModel"
                            class="input-entry"
                            [ngClass]="{
                                failure:
                                    restoreNotifTimeOverride.invalid &&
                                    (restoreNotifTimeOverride.dirty || restoreNotifTimeOverride.touched)
                            }"
                            name="restoreNotifTimeOverride"
                            placeholder="0"
                            maxlength="16"
                            [(ngModel)]="registration.restoreNotifTimeOverride"
                            data-automation="restoreNotifTimeOverrideValue"
                            data-url-persist
                            (keydown)="onKeydownNegativeValues($event)"
                        />
                    </mat-form-field>
                </div>

                <div class="form-entry">
                    <!--- Anticipated KW --->
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'registration.anticipated_kw' | translate }}</mat-label>
                        <input
                            matInput
                            id="anticipatedKW"
                            type="number"
                            step="1"
                            #anticipatedKW="ngModel"
                            class="input-entry"
                            [ngClass]="{
                                failure: anticipatedKW.invalid && (anticipatedKW.dirty || anticipatedKW.touched)
                            }"
                            name="anticipatedKW"
                            placeholder="0"
                            maxlength="16"
                            min="0"
                            [(ngModel)]="registration.dispatchConf.anticipatedKw"
                            data-automation="anticipatedKW"
                            data-url-persist
                            required
                        />
                        <mat-error *ngIf="anticipatedKW.invalid">
                            <mat-error *ngIf="anticipatedKW.errors.required">
                                <span class="input-entry">{{ 'registration.validation.required' | translate }}</span>
                            </mat-error>
                            <mat-error *ngIf="anticipatedKW.errors.min">
                                <span class="input-entry">{{
                                    'registration.validation.equal_or_greater_than_zero' | translate
                                }}</span>
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="form-entry">
                    <!--- Max Consecutive Days --->
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'registration.max_consecutive_days' | translate }}</mat-label>
                        <input
                            matInput
                            id="maxConsecutiveDays"
                            type="number"
                            step="1"
                            #maxConsecutiveDays="ngModel"
                            class="input-entry"
                            [ngClass]="{
                                failure:
                                    maxConsecutiveDays.invalid &&
                                    (maxConsecutiveDays.dirty || maxConsecutiveDays.touched)
                            }"
                            name="maxConsecutiveDays"
                            placeholder="0"
                            maxlength="16"
                            min="0"
                            [(ngModel)]="registration.dispatchConf.maxConsecutiveDays"
                            data-automation="maxConsecutiveDays"
                            data-url-persist
                        />
                        <mat-error *ngIf="maxConsecutiveDays.invalid">
                            <mat-error *ngIf="maxConsecutiveDays.errors.required">
                                <span class="input-entry">{{ 'registration.validation.required' | translate }}</span>
                            </mat-error>
                            <mat-error *ngIf="maxConsecutiveDays.errors.min">
                                <span class="input-entry">{{
                                    'registration.validation.equal_or_greater_than_zero' | translate
                                }}</span>
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="form-entry">
                    <!--- Max Consecutive Hours --->
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'registration.max_consecutive_hours' | translate }}</mat-label>
                        <input
                            matInput
                            id="maxConsecutiveHours"
                            type="number"
                            step="1"
                            #maxConsecutiveHours="ngModel"
                            class="input-entry"
                            [ngClass]="{
                                failure:
                                    maxConsecutiveHours.invalid &&
                                    (maxConsecutiveHours.dirty || maxConsecutiveHours.touched)
                            }"
                            name="maxConsecutiveHours"
                            placeholder="0"
                            maxlength="16"
                            min="0"
                            [(ngModel)]="registration.dispatchConf.maxConsecutiveHours"
                            data-automation="maxConsecutiveHours"
                            data-url-persist
                        />
                        <mat-error *ngIf="maxConsecutiveHours.invalid">
                            <mat-error *ngIf="maxConsecutiveHours.errors.required">
                                <span class="input-entry">{{ 'registration.validation.required' | translate }}</span>
                            </mat-error>
                            <mat-error *ngIf="maxConsecutiveHours.errors.min">
                                <span class="input-entry">{{
                                    'registration.validation.equal_or_greater_than_zero' | translate
                                }}</span>
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="form-entry">
                    <!--- Max Events Per Season --->
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'registration.max_events_per_season' | translate }}</mat-label>
                        <input
                            matInput
                            id="maxEventsPerSeason"
                            type="number"
                            step="1"
                            #maxEventsPerSeason="ngModel"
                            class="input-entry"
                            [ngClass]="{
                                failure:
                                    maxEventsPerSeason.invalid &&
                                    (maxEventsPerSeason.dirty || maxEventsPerSeason.touched)
                            }"
                            name="maxEventsPerSeason"
                            placeholder="0"
                            maxlength="16"
                            min="0"
                            [(ngModel)]="registration.dispatchConf.maxEventsPerSeason"
                            data-automation="maxEventsPerSeason"
                            data-url-persist
                        />
                        <mat-error *ngIf="maxEventsPerSeason.invalid">
                            <mat-error *ngIf="maxEventsPerSeason.errors.required">
                                <span class="input-entry">{{ 'registration.validation.required' | translate }}</span>
                            </mat-error>
                            <mat-error *ngIf="maxEventsPerSeason.errors.min">
                                <span class="input-entry">{{
                                    'registration.validation.equal_or_greater_than_zero' | translate
                                }}</span>
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>

                <!--- Max Hours Per Season --->
                <div class="form-entry">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'registration.max_hours_per_season' | translate }}</mat-label>
                        <input
                            matInput
                            id="maxHoursPerSeason"
                            type="number"
                            step="1"
                            #maxHoursPerSeason="ngModel"
                            class="input-entry"
                            [ngClass]="{
                                failure:
                                    maxHoursPerSeason.invalid && (maxHoursPerSeason.dirty || maxHoursPerSeason.touched)
                            }"
                            name="maxHoursPerSeason"
                            placeholder="0"
                            maxlength="16"
                            min="0"
                            [(ngModel)]="registration.dispatchConf.maxHoursPerSeason"
                            data-automation="maxHoursPerSeason"
                            data-url-persist
                        />
                        <mat-error *ngIf="maxHoursPerSeason.invalid">
                            <mat-error *ngIf="maxHoursPerSeason.errors.required">
                                <span class="input-entry">{{ 'registration.validation.required' | translate }}</span>
                            </mat-error>
                            <mat-error *ngIf="maxHoursPerSeason.errors.min">
                                <span class="input-entry">{{
                                    'registration.validation.equal_or_greater_than_zero' | translate
                                }}</span>
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="form-entry">
                    <!--- Max Hours Per Day --->
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'registration.max_hours_per_day' | translate }}</mat-label>
                        <input
                            matInput
                            id="maxHoursPerDay"
                            type="number"
                            step="1"
                            #maxHoursPerDay="ngModel"
                            class="input-entry"
                            [ngClass]="{
                                failure: maxHoursPerDay.invalid && (maxHoursPerDay.dirty || maxHoursPerDay.touched)
                            }"
                            name="maxHoursPerDay"
                            placeholder="0"
                            maxlength="2"
                            min="0"
                            max="24"
                            [(ngModel)]="registration.maxHoursPerDay"
                            data-automation="maxHoursPerDay"
                            data-url-persist
                        />
                        <mat-error *ngIf="maxHoursPerDay.invalid">
                            <mat-error *ngIf="maxHoursPerDay.errors.min">
                                <span class="input-entry">{{
                                    'registration.validation.hours_per_day_min' | translate
                                }}</span>
                            </mat-error>
                            <mat-error *ngIf="maxHoursPerDay.errors.max">
                                <span class="input-entry">{{
                                    'registration.validation.hours_per_day_max' | translate
                                }}</span>
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="form-entry label-info">
                    <!--- Min Event Duration --->
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label class="info-title">{{ 'registration.min_event_duration' | translate }}</mat-label>
                        <input
                            matInput
                            id="minEventDuration"
                            type="number"
                            step="1"
                            #minEventDuration="ngModel"
                            class="input-entry"
                            [ngClass]="{
                                failure:
                                    minEventDuration.invalid && (minEventDuration.dirty || minEventDuration.touched)
                            }"
                            name="minEventDuration"
                            placeholder="0"
                            maxlength="7"
                            min="0"
                            (keydown)="onKeydown($event)"
                            [(ngModel)]="registration.minEventDuration"
                            data-automation="minEventDuration"
                            data-url-persist
                        />
                        <mat-error *ngIf="minEventDuration.invalid">
                            <mat-error *ngIf="minEventDuration.errors.min">
                                <span class="input-entry">{{
                                    'registration.validation.equal_or_greater_than_zero' | translate
                                }}</span>
                            </mat-error>
                        </mat-error>
                        <mat-icon
                            svgIcon="info"
                            data-automation="minEventDurationIcon"
                            matSuffix
                            class="tooltip-icon"
                            matTooltip="{{ 'registration.tooltip.min_event_duration' | translate }}"
                        ></mat-icon>
                    </mat-form-field>
                </div>

                <div class="form-entry label-info">
                    <!--- minutes between events --->
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label data-automation="minutesBetweenEvents">{{
                            'registration.minutes_between_events' | translate
                        }}</mat-label>
                        <input
                            matInput
                            id="minutesBetweenEvents"
                            type="number"
                            step="1"
                            #minutesBetweenEvents="ngModel"
                            class="input-entry"
                            [ngClass]="{
                                failure:
                                    minutesBetweenEvents.invalid &&
                                    (minutesBetweenEvents.dirty || minutesBetweenEvents.touched)
                            }"
                            name="minutesBetweenEvents"
                            placeholder="0"
                            maxlength="16"
                            min="0"
                            (keydown)="onKeydown($event)"
                            [(ngModel)]="registration.dispatchConf.minutesBetweenEvents"
                            data-automation="minutesBetweenEventsInput"
                            data-url-persist
                        />
                        <mat-icon
                            svgIcon="info"
                            data-automation="minutesBetweenEventsIcon"
                            matSuffix
                            class="tooltip-icon"
                            matTooltip="{{ 'registration.tooltip.minutes_between_events' | translate }}"
                        ></mat-icon>
                        <mat-error *ngIf="minutesBetweenEvents.invalid">
                            <mat-error *ngIf="minutesBetweenEvents.errors.required">
                                <span class="input-entry">{{ 'registration.validation.required' | translate }}</span>
                            </mat-error>
                            <mat-error *ngIf="minutesBetweenEvents.errors.min">
                                <span class="input-entry">{{
                                    'registration.validation.equal_or_greater_than_zero' | translate
                                }}</span>
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="form-entry label-info">
                    <!--- Drop to KW --->
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label class="info-title">{{ 'registration.drop_to_value' | translate }}</mat-label>
                        <input
                            matInput
                            id="dropToValue"
                            type="number"
                            step="1"
                            #dropToValue="ngModel"
                            class="input-entry"
                            [ngClass]="{
                                failure: dropToValue.invalid && (dropToValue.dirty || dropToValue.touched)
                            }"
                            name="dropToValue"
                            placeholder="0"
                            maxlength="2"
                            (keydown)="onKeydownNegativeValues($event)"
                            [(ngModel)]="registration.dropToValue"
                            data-automation="dropToValue"
                            data-url-persist
                        />
                        <mat-icon
                            svgIcon="info"
                            data-automation="dropToValueIcon"
                            matSuffix
                            class="tooltip-icon"
                            matTooltip="{{ 'registration.tooltip.drop_to_value' | translate }}"
                        ></mat-icon>
                    </mat-form-field>
                </div>

                <div class="form-entry label-info">
                    <!--- Ramp Down Rate --->
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'registration.ramp_down_rate' | translate }}</mat-label>
                        <input
                            matInput
                            id="rampDownRate"
                            type="number"
                            step="1"
                            #rampDownRate="ngModel"
                            class="input-entry"
                            [ngClass]="{
                                failure: rampDownRate.invalid && (rampDownRate.dirty || rampDownRate.touched)
                            }"
                            name="rampDownRate"
                            placeholder="0"
                            maxlength="2"
                            (keydown)="onKeydownNegativeValues($event)"
                            [(ngModel)]="registration.dispatchConf.rampDownRate"
                            data-automation="rampDownRate"
                            data-url-persist
                        />
                        <mat-icon
                            svgIcon="info"
                            data-automation="rampDownRateIcon"
                            matSuffix
                            class="tooltip-icon"
                            matTooltip="{{ 'registration.tooltip.ramp_down_rate' | translate }}"
                        ></mat-icon>
                    </mat-form-field>
                </div>

                <div class="form-entry label-info">
                    <!--- Ramp Up Rate --->
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'registration.ramp_up_rate' | translate }}</mat-label>
                        <input
                            matInput
                            id="rampUpRate"
                            type="number"
                            step="1"
                            #rampUpRate="ngModel"
                            class="input-entry"
                            [ngClass]="{
                                failure: rampUpRate.invalid && (rampUpRate.dirty || rampUpRate.touched)
                            }"
                            name="rampUpRate"
                            placeholder="0"
                            maxlength="2"
                            (keydown)="onKeydownNegativeValues($event)"
                            [(ngModel)]="registration.dispatchConf.rampUpRate"
                            data-automation="rampUpRate"
                            data-url-persist
                        />
                        <mat-icon
                            svgIcon="info"
                            data-automation="rampUpRateIcon"
                            matSuffix
                            class="tooltip-icon"
                            matTooltip="{{ 'registration.tooltip.ramp_up_rate' | translate }}"
                        ></mat-icon>
                    </mat-form-field>
                </div>

                <div class="form-entry label-info">
                    <!-- static_baseline_value -->
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'registration.baseline_value' | translate }}</mat-label>
                        <input
                            matInput
                            id="baselineValue"
                            type="number"
                            step=".01"
                            #baselineValue="ngModel"
                            class="input-entry"
                            [ngClass]="{
                                failure: baselineValue.invalid && (baselineValue.dirty || baselineValue.touched)
                            }"
                            name="baselineValue"
                            placeholder="0"
                            maxlength="80"
                            min="0"
                            [(ngModel)]="registration.baselineValue"
                            data-automation="baselineValue"
                            data-url-persist
                        />
                        <mat-error *ngIf="baselineValue.invalid">
                            <mat-error *ngIf="baselineValue.errors.min">
                                <span class="input-entry">{{
                                    'registration.validation.equal_or_greater_than_zero' | translate
                                }}</span>
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="form-entry label-info">
                    <!--- Baseline Registration Configuration --->
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'registration.baseline_registration_configuration' | translate }} </mat-label>
                        <mat-select
                            id="baseline_def_id"
                            name="baseline_def_id"
                            #baselineDefId="ngModel"
                            class="input-entry create-select"
                            [ngClass]="{
                                failure: baselineDefId.invalid && (baselineDefId.dirty || baselineDefId.touched)
                            }"
                            [(ngModel)]="regBaselineDefinition"
                            data-automation="baselineDefintion"
                            [disabled]="
                                !baselineDefinitions || baselineDefinitions.length == 0 || !registration.productId
                            "
                            placeholder="{{ getBaselineDefinitionPlaceholder() | translate }}"
                            (selectionChange)="changeBaselineDefinition()"
                        >
                            <mat-option *ngFor="let baselineDef of getBaslineDefinitions()" [value]="baselineDef.id">{{
                                baselineDef.displayLabel || baselineDef.blTemplateName
                            }}</mat-option>
                        </mat-select>
                        <mat-icon
                            svgIcon="{{ errorTooltip ? 'warning' : 'info' }}"
                            data-automation="baselineDefintionIcon"
                            matSuffix
                            [ngClass]="errorTooltip ? 'warning-icon tooltip-icon' : 'tooltip-icon'"
                            matTooltip="{{ getBaselineDefinitionsTooltip() | translate }}"
                        ></mat-icon>
                    </mat-form-field>
                </div>

                <div class="form-entry">
                    <!--- LOA Status --->
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'registration.loa_status' | translate }}</mat-label>
                        <mat-select
                            id="loaStatus"
                            name="loaStatus"
                            #loaStatus="ngModel"
                            class="input-entry create-select"
                            [(ngModel)]="registration.loaStatus"
                            data-automation="loaStatus"
                            disabled
                        >
                            <mat-option *ngFor="let loaStatus of loaStatuses" [value]="loaStatus.code">{{
                                loaStatus.displayLabel
                            }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="form-entry label-info">
                    <!-- Energy Reduction Plan -->
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ planLabel }}</mat-label>
                        <textarea
                            id="energyReductionPlan"
                            data-automation="energyReductionPlan"
                            name="energyReductionPlan"
                            [(ngModel)]="registration.energyReductionPlan"
                            #energyReductionPlan="ngModel"
                            [placeholder]="planPlaceholder"
                            class="input-entry"
                            matInput
                            [ngClass]="{
                                failure:
                                    energyReductionPlan.invalid &&
                                    (energyReductionPlan.dirty || energyReductionPlan.touched)
                            }"
                            cdkTextareaAutosize
                            [cdkAutosizeMinRows]="2"
                            [cdkAutosizeMaxRows]="5"
                        >
                        </textarea>

                        <mat-icon
                            svgIcon="info"
                            data-automation="energyReductionPlanIcon"
                            matSuffix
                            class="tooltip-icon"
                            matTooltip="{{ 'registration.tooltip.energy_reduction_plan' | translate }}"
                        ></mat-icon>
                    </mat-form-field>
                </div>

                <div class="form-entry">
                    <!-- Project Manager Name -->
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'registration.project_manager_name' | translate }}</mat-label>
                        <input
                            matInput
                            id="projectManagerName"
                            placeholder="{{ 'registration.create.placeholder.project_manager_name' | translate }}"
                            type="string"
                            #projectManagerName="ngModel"
                            class="input-entry"
                            [ngClass]="{
                                failure:
                                    projectManagerName.invalid &&
                                    (projectManagerName.dirty || projectManagerName.touched)
                            }"
                            name="projectManagerName"
                            [(ngModel)]="registration.projectManagerName"
                            data-automation="projectManagerName"
                            data-url-persist
                        />
                    </mat-form-field>
                </div>

                <div class="form-entry">
                    <!-- Project Manager ID -->
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'registration.project_manager_id' | translate }}</mat-label>
                        <input
                            matInput
                            id="projectManagerId"
                            placeholder="{{ 'registration.create.placeholder.project_manager_id' | translate }}"
                            type="string"
                            #projectManagerId="ngModel"
                            class="input-entry"
                            [ngClass]="{
                                failure:
                                    projectManagerId.invalid && (projectManagerId.dirty || projectManagerId.touched)
                            }"
                            name="projectManagerId"
                            [(ngModel)]="registration.projectManagerId"
                            data-automation="projectManagerId"
                            data-url-persist
                        />
                    </mat-form-field>
                </div>

                <div class="form-entry">
                    <!-- Account Manager Name -->
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'registration.account_manager_name' | translate }}</mat-label>
                        <input
                            matInput
                            id="accountManagerName"
                            placeholder="{{ 'registration.create.placeholder.account_manager_name' | translate }}"
                            type="string"
                            #accountManagerName="ngModel"
                            class="input-entry"
                            [ngClass]="{
                                failure:
                                    accountManagerName.invalid &&
                                    (accountManagerName.dirty || accountManagerName.touched)
                            }"
                            name="accountManagerName"
                            [(ngModel)]="registration.accountManagerName"
                            data-automation="accountManagerName"
                            data-url-persist
                        />
                    </mat-form-field>
                </div>

                <div class="form-entry">
                    <!-- Account Manager ID -->
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'registration.account_manager_id' | translate }}</mat-label>
                        <input
                            matInput
                            id="accountManagerId"
                            placeholder="{{ 'registration.create.placeholder.account_manager_id' | translate }}"
                            type="string"
                            #accountManagerId="ngModel"
                            class="input-entry"
                            [ngClass]="{
                                failure:
                                    accountManagerId.invalid && (accountManagerId.dirty || accountManagerId.touched)
                            }"
                            name="accountManagerId"
                            [(ngModel)]="registration.accountManagerId"
                            data-automation="accountManagerId"
                            data-url-persist
                        />
                    </mat-form-field>
                </div>

                <div class="form-entry-column">
                    <!--- Estimate Performance Indicator --->
                    <div class="checkbox-wrapper">
                        <mat-checkbox
                            id="estimatePerformance"
                            #estimatePerformance="ngModel"
                            class="input-entry"
                            [ngClass]="{
                                failure:
                                    estimatePerformance.invalid &&
                                    (estimatePerformance.dirty || estimatePerformance.touched)
                            }"
                            name="estimatePerformance"
                            [(ngModel)]="registration.dispatchConf.estimatePerformanceInd"
                            data-automation="estimatePerformance"
                            data-url-persist
                        >
                            {{ 'registration.estimate_performance' | translate }}
                        </mat-checkbox>
                        <mat-error *ngIf="estimatePerformance.invalid">
                            <mat-error *ngIf="estimatePerformance.errors.required">
                                <span class="input-entry">{{ 'registration.validation.required' | translate }}</span>
                            </mat-error>
                        </mat-error>
                    </div>

                    <!-- Preauthorized --->
                    <div class="checkbox-wrapper">
                        <mat-checkbox
                            id="preauthorized"
                            #preauthorized="ngModel"
                            [ngClass]="{
                                failure: preauthorized.invalid && (preauthorized.dirty || preauthorized.touched)
                            }"
                            name="preauthorized"
                            [(ngModel)]="registration.preauthorized"
                            data-automation="preauthorized"
                            data-url-persist
                            >{{ 'registration.preauthorized' | translate }}</mat-checkbox
                        >
                        <mat-error *ngIf="preauthorized.invalid">
                            <mat-error *ngIf="preauthorized.errors.required">
                                <span data-automation="alertRequired">{{
                                    'registration.validation.required' | translate
                                }}</span>
                            </mat-error>
                        </mat-error>
                    </div>
                </div>

                <!--- Ready to Test --->
                <div class="checkbox-wrapper">
                    <mat-checkbox
                        id="readyToTest"
                        #readyToTest="ngModel"
                        class="input-entry"
                        name="readyToTest"
                        [(ngModel)]="registration.readyToTest"
                        data-automation="readyToTest"
                        data-url-persist
                    >
                        {{ 'registration.ready_to_test' | translate }}
                    </mat-checkbox>
                </div>

                <!-- Telemetry Points -->
                <div class="telemetry-points-header form-header">
                    <h2 data-automation="points-header">
                        {{ 'telemetry_point.title' | translate }}
                    </h2>
                    <mat-icon
                        data-automation="add-point"
                        class="add-point"
                        *ngIf="isEditMode || isCreateMode"
                        svgIcon="plusButton"
                        (click)="addDynamicPoint()"
                    ></mat-icon>
                </div>

                <div class="points-content">
                    <div class="empty-points" *ngIf="!hasPoints">
                        <div><mat-icon svgIcon="noData"></mat-icon></div>
                        <h6>{{ 'telemetry_point.empty_title' | translate }}</h6>
                        <div data-automation="empty-points" class="empty-filler">
                            {{ 'telemetry_point.empty' | translate }}
                        </div>
                    </div>

                    <ng-container *ngIf="hasPoints && appData">
                        <ng-container *ngFor="let point of points; let i = index; let c = count">
                            <app-telemetry-point
                                *ngIf="!point.deleted"
                                [mode]="mode"
                                [point]="point"
                                [userLocale]="userLocale"
                                [selectedChannels]="selectedChannels"
                                [appData]="appData"
                                [registrationName]="registrationName"
                                [siteId]="registration.siteId"
                                [reportingIntervals]="reportingIntervals"
                                [dataProviders]="dataProviders"
                                [index]="i"
                                [count]="c"
                                [multiLocaleConfig]="multiLocaleConfig"
                                [registration]="registration"
                                (addDynamicPoint)="addDynamicPoint()"
                                (deleteDynamicPoint)="deleteDynamicPoint($event)"
                                (updateSelectedChannels)="updateSelectedChannels()"
                                (updateSourceIds)="updateSourceIds()"
                            >
                            </app-telemetry-point>
                        </ng-container>
                    </ng-container>
                </div>

                <!-- Baseline Points -->
                <div class="baseline-points-header form-header">
                    <h2 data-automation="baseline-points-header">
                        {{ 'baseline_point.title' | translate }}
                    </h2>
                    <mat-icon
                        data-automation="add-baseline-point"
                        class="add-baseline-point"
                        *ngIf="isEditMode || isCreateMode"
                        svgIcon="plusButton"
                        (click)="addDynamicBaselinePoint()"
                    ></mat-icon>
                </div>
                <div class="baseline-points-content">
                    <div class="empty-baseline-points" *ngIf="!hasBaselinePoints">
                        <div><mat-icon svgIcon="noData"></mat-icon></div>
                        <h6>{{ 'baseline_point.empty_title' | translate }}</h6>
                        <div data-automation="empty-baseline-points" class="empty-filler">
                            {{ 'baseline_point.empty' | translate }}
                        </div>
                    </div>

                    <ng-container *ngIf="hasBaselinePoints && appData">
                        <ng-container
                            *ngFor="
                                let baselinePoint of baselinePoints;
                                let indexBaselinePoint = index;
                                let countBaselinePoint = count
                            "
                        >
                            <app-baseline-point
                                *ngIf="!baselinePoint.deleted"
                                [mode]="mode"
                                [baselinePoint]="baselinePoint"
                                [userLocale]="userLocale"
                                [appData]="appData"
                                [selectedBaselineChannels]="selectedBaselineChannels"
                                [registrationName]="registrationName"
                                [siteId]="registration.siteId"
                                [reportingIntervals]="reportingIntervals"
                                [dataProviders]="dataProviders"
                                [index]="indexBaselinePoint"
                                [count]="countBaselinePoint"
                                [multiLocaleConfig]="multiLocaleConfig"
                                [registration]="registration"
                                [baselineDefinitions]="baselineDefinitions"
                                (updateSelectedBaselinePointChannels)="updateSelectedBaselinePointChannels()"
                                (addDynamicBaselinePoint)="addDynamicBaselinePoint()"
                                (deleteDynamicBaselinePoint)="deleteDynamicBaselinePoint($event)"
                            >
                            </app-baseline-point>
                        </ng-container>
                    </ng-container>
                </div>

                <!-- Transform rules -->
                <app-transform-rules
                    [rules]="transformRules"
                    [availabilityPoints]="availabilityPoints"
                    [isEditMode]="isEditMode"
                    [isCreateMode]="isCreateMode"
                ></app-transform-rules>
            </div>
        </ng-container>
    </ng-container>
    <!--- VIEW --->
    <ng-container *ngIf="isViewMode || isRetired">
        <div class="form-wrapper">
            <!--- Status edit retired--->
            <div *ngIf="isEditMode">
                <div class="form-entry">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'registration.status' | translate }}</mat-label>
                        <mat-select
                            id="statusEditable"
                            name="status"
                            #status="ngModel"
                            class="input-entry create-select"
                            [ngClass]="{ failure: status.invalid && (status.dirty || status.touched) }"
                            [(ngModel)]="registration.status"
                            data-automation="status"
                            required
                        >
                            <mat-option *ngFor="let status of statuses" [value]="status.code">{{
                                status.displayLabel
                            }}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="status.invalid">
                            <mat-error *ngIf="status.errors.pattern">
                                {{ 'registration.validation.required' | translate }}
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>
                <br />
            </div>
            <!--- NAME --->
            <div class="form-entry">
                <mat-label class="info-title">{{ 'registration.registration_name' | translate }}:</mat-label>
                <mat-label class="info-value" data-automation="regName">
                    <span matTooltip="{{ registration.name }}" matTooltipPosition="above">{{ registration.name }}</span>
                </mat-label>
            </div>

            <!--- Registration ID --->
            <div class="form-entry">
                <mat-label class="info-title">{{ 'registration.registration_id' | translate }}:</mat-label>
                <mat-label class="info-value" data-automation="regId">
                    {{ registration.regId }}
                </mat-label>
            </div>

            <!--- CRM ID --->
            <div class="form-entry">
                <mat-label class="info-title">{{ 'registration.crm_id' | translate }}:</mat-label>
                <mat-label class="info-value" data-automation="crmId">
                    {{ registration.crmId }}
                </mat-label>
            </div>

            <!--- Locale --->
            <div class="form-entry">
                <mat-label class="info-title">{{ 'registration.locale' | translate }}:</mat-label>
                <mat-label class="info-value" data-automation="locale">
                    {{ getLocaleForRegistration().displayLabel }}
                </mat-label>
            </div>

            <!--- Timezone --->
            <div class="form-entry">
                <mat-label class="info-title">{{ 'registration.timezone' | translate }}:</mat-label>
                <mat-label class="info-value" data-automation="timezone">
                    {{ getTimezoneForRegistration().displayLabel }}
                </mat-label>
            </div>

            <!--- SDP --->
            <div class="form-entry">
                <mat-label class="info-title">{{ 'registration.sdp' | translate }}:</mat-label>
                <mat-label class="info-value" data-automation="sdpSelector">
                    <span matTooltip="{{ getSDPDisplayLabel() }}" matTooltipPosition="above">{{
                        getSDPDisplayLabel()
                    }}</span>
                </mat-label>
            </div>

            <!--- PRODUCT --->
            <div class="form-entry">
                <mat-label class="info-title">{{ 'registration.product' | translate }}:</mat-label>
                <mat-label class="info-value" data-automation="productSelector">
                    {{ getProductForRegistration().displayLabel }}
                </mat-label>
            </div>

            <!--- Baseline Configuration --->
            <div class="form-entry" *ngIf="registration.fullReg">
                <mat-label class="info-title label-info"
                    >{{ 'registration.baseline_registration_configuration' | translate }}:
                    <mat-icon
                        svgIcon="{{ errorTooltip ? 'warning' : 'info' }}"
                        data-automation="baselineDefintionIcon"
                        matSuffix
                        [ngClass]="errorTooltip ? 'warning-icon tooltip-icon' : 'tooltip-icon'"
                        matTooltip="{{ getBaselineDefinitionsTooltip() | translate }}"
                    ></mat-icon>
                </mat-label>
                <mat-label class="info-value" data-automation="baselineDefintion">
                    {{ getDefinitionForRegistration() | translate }}
                </mat-label>
            </div>

            <!--- Status --->
            <div class="form-entry" *ngIf="!isEditMode">
                <mat-label class="info-title">{{ 'registration.status' | translate }}:</mat-label>
                <mat-label class="info-value" data-automation="status">
                    {{ getStatusForRegistration().displayLabel }}
                </mat-label>
            </div>

            <!--- DESCRIPTION --->
            <div class="form-entry">
                <mat-label class="info-title">{{ 'registration.description' | translate }}:</mat-label>
                <mat-label class="wrapped" data-automation="description" [matTooltip]="registration.description">
                    {{ registration.description }}
                </mat-label>
            </div>
        </div>

        <div class="form-wrapper" *ngIf="registration.fullReg">
            <div class="form-entry">
                <!--- Asset --->
                <mat-label class="info-title">{{ 'registration.asset' | translate }}:</mat-label>
                <a
                    *ngIf="registration.flexibleAssetId"
                    href="{{ getAssetAdminUrl(getAssetId(), 'view') }}"
                    data-automation="assetSelector"
                    target="_blank"
                    class="new-tab-link truncate"
                >
                    {{ getAssetDisplayLabel() }}
                    <mat-icon>open_in_new</mat-icon>
                </a>
                <mat-label
                    *ngIf="!getAssetDisplayLabel()"
                    class="info-value info-placeHolder"
                    data-automation="assetSelector"
                >
                    {{ 'registration.view.no_asset' | translate }}
                </mat-label>
            </div>

            <div class="form-entry">
                <!--- Site --->
                <mat-label data-automation="site" class="info-title">{{ 'registration.site' | translate }}:</mat-label>
                <a
                    *ngIf="ownerId"
                    href="{{ getCustomerAdminUrl(ownerId) }}"
                    data-automation="siteSelector"
                    target="_blank"
                    class="new-tab-link truncate"
                    [matTooltip]="site?.display_label"
                >
                    {{ site?.display_label }}
                    <mat-icon>open_in_new</mat-icon>
                </a>
                <mat-label *ngIf="!ownerId" class="info-value info-placeHolder" data-automation="siteSelector">
                    {{ 'registration.view.no_site' | translate }}
                </mat-label>
            </div>

            <div class="form-entry">
                <!--- Minimum Value --->
                <mat-label class="info-title">{{ 'registration.minimum_value' | translate }}:</mat-label>
                <mat-label class="info-value" data-automation="minimumValue">
                    {{ registration.minimumValue }}
                </mat-label>
            </div>

            <div class="form-entry">
                <!--- Portfolio --->
                <mat-label class="info-title">{{ 'registration.portfolio' | translate }}:</mat-label>
                <mat-label class="info-value" *ngIf="getPortfolioForRegistration()" data-automation="portfolioSelector">
                    {{ getPortfolioForRegistration() }}
                </mat-label>
                <mat-label
                    *ngIf="!getPortfolioForRegistration()"
                    class="info-value info-placeHolder"
                    data-automation="portfolioSelector"
                >
                    {{ 'registration.view.no_portfolio' | translate }}
                </mat-label>
            </div>

            <div class="form-entry label-info">
                <!--- Maximum Value --->
                <mat-label class="info-title"
                    >{{ 'registration.maximum_value' | translate }}:
                    <mat-icon
                        svgIcon="info"
                        data-automation="maximumValueIcon"
                        class="tooltip-icon"
                        [matTooltipPosition]="'below'"
                        matTooltip="{{ 'registration.tooltip.maximum_value' | translate }}"
                    ></mat-icon>
                </mat-label>
                <mat-label class="info-value" data-automation="maximumValue">
                    {{ registration.maximumValue }}
                </mat-label>
            </div>

            <div class="form-entry">
                <!--- Control Type --->
                <mat-label class="info-title">{{ 'registration.control_type' | translate }}:</mat-label>
                <mat-label *ngIf="getControlTypeForRegistration()" class="info-value" data-automation="controlType">
                    {{ getControlTypeForRegistration() }}
                </mat-label>

                <mat-label
                    *ngIf="!getControlTypeForRegistration()"
                    class="info-value info-placeHolder"
                    data-automation="controlType"
                >
                    {{ 'registration.view.no_control_type' | translate }}
                </mat-label>
            </div>

            <div class="form-entry">
                <!--- Nomination Offset --->
                <mat-label class="info-title">{{ 'registration.nomination_offset' | translate }}:</mat-label>
                <mat-label class="info-value" data-automation="nominationOffset">
                    {{ registration.nominationOffset ? registration.nominationOffset : 0 }}
                </mat-label>
            </div>

            <div class="form-entry">
                <!--- registration external reference id --->
                <mat-label class="info-title"
                    >{{ 'registration.registration_external_reference_id' | translate }}:</mat-label
                >
                <mat-label class="info-value" data-automation="registrationExternalReferenceId">
                    {{ registration.registrationExternalReferenceId }}
                </mat-label>
            </div>

            <div class="form-entry">
                <!--- registration location id --->
                <mat-label class="info-title">{{ 'registration.registration_location_id' | translate }}:</mat-label>
                <mat-label class="info-value" data-automation="registrationLocationId">
                    {{
                        registration.marketConf && registration.marketConf.locationId
                            ? registration.marketConf.locationId
                            : null
                    }}
                </mat-label>
            </div>

            <div class="form-entry">
                <!--- Effective Date --->
                <mat-label class="info-title">{{ 'registration.effective_date' | translate }}:</mat-label>
                <mat-label class="info-value" data-automation="effectiveDate">
                    {{ registration.effectiveDttm | date: 'shortDate' }}
                </mat-label>
            </div>

            <div class="form-entry">
                <!--- Control Timeout Override --->
                <mat-label class="info-title">{{ 'registration.control_timeout_override' | translate }}:</mat-label>
                <mat-label class="info-value" data-automation="controlTimeoutOverride">
                    {{ registration.controlTimeoutOverride ? registration.controlTimeoutOverride : 0 }}
                </mat-label>
            </div>

            <div class="form-entry">
                <!--- Control Time Override --->
                <mat-label class="info-title">{{ 'registration.control_time_override' | translate }}:</mat-label>
                <mat-label class="info-value" data-automation="controlTimeOverride">
                    {{ registration.controlTimeOverride ? registration.controlTimeOverride : 0 }}
                </mat-label>
            </div>

            <div class="form-entry">
                <!--- Restore Time Override --->
                <mat-label class="info-title" data-automation="restoreTimeOverride"
                    >{{ 'registration.restore_time_override' | translate }}:</mat-label
                >
                <mat-label class="info-value" data-automation="restoreTimeOverrideValue">
                    {{ registration.restoreTimeOverride ? registration.restoreTimeOverride : 0 }}
                </mat-label>
            </div>

            <div class="form-entry">
                <!--- Initial Notification Time Override --->
                <mat-label class="info-title" data-automation="initialNotifTimeOverride"
                    >{{ 'registration.initial_notif_time_override' | translate }}:</mat-label
                >
                <mat-label class="info-value" data-automation="initialNotifTimeOverrideValue">
                    {{ registration.initialNotifTimeOverride ? registration.initialNotifTimeOverride : 0 }}
                </mat-label>
            </div>

            <div class="form-entry">
                <!--- Restore Notification Time Override --->
                <mat-label class="info-title" data-automation="restoreNotifTimeOverride"
                    >{{ 'registration.restore_notif_time_override' | translate }}:</mat-label
                >
                <mat-label class="info-value" data-automation="restoreNotifTimeOverrideValue">
                    {{ registration.restoreNotifTimeOverride ? registration.restoreNotifTimeOverride : 0 }}
                </mat-label>
            </div>

            <div class="form-entry">
                <!--- Termination Date --->
                <mat-label class="info-title">{{ 'registration.termination_date' | translate }}:</mat-label>
                <mat-label class="info-value" data-automation="terminationDate">
                    {{ registration.terminationDttm | date: 'shortDate' }}
                </mat-label>
            </div>

            <div class="form-entry">
                <!--- Event Advanced Notice --->
                <mat-label class="info-title">{{ 'registration.event_advance_notice' | translate }}:</mat-label>
                <mat-label class="info-value" data-automation="eventAdvanceNotice">
                    {{ registration.eventAdvanceNotice ? registration.eventAdvanceNotice : 0 }}
                </mat-label>
            </div>

            <div class="form-entry">
                <!--- Dispatch Config / Anticipated KW --->
                <mat-label class="info-title">{{ 'registration.anticipated_kw' | translate }}:</mat-label>
                <mat-label class="info-value" data-automation="anticipatedKw">
                    {{
                        registration.dispatchConf && registration.dispatchConf.anticipatedKw
                            ? registration.dispatchConf.anticipatedKw
                            : 0
                    }}
                </mat-label>
            </div>

            <div class="form-entry">
                <!--- Dispatch Config / Max events Per Season --->
                <mat-label class="info-title">{{ 'registration.max_events_per_season' | translate }}:</mat-label>
                <mat-label class="info-value" data-automation="maxEventsPerSeason">
                    {{
                        registration.dispatchConf && registration.dispatchConf.maxEventsPerSeason
                            ? registration.dispatchConf.maxEventsPerSeason
                            : 0
                    }}
                </mat-label>
            </div>

            <div class="form-entry">
                <!--- Dispatch Config / Max Days --->
                <mat-label class="info-title">{{ 'registration.max_consecutive_days' | translate }}:</mat-label>
                <mat-label class="info-value" data-automation="maxConsecutiveDays">
                    {{
                        registration.dispatchConf && registration.dispatchConf.maxConsecutiveDays
                            ? registration.dispatchConf.maxConsecutiveDays
                            : 0
                    }}
                </mat-label>
            </div>

            <div class="form-entry">
                <!--- Dispatch Config / Max Hours --->
                <mat-label class="info-title">{{ 'registration.max_consecutive_hours' | translate }}:</mat-label>
                <mat-label class="info-value" data-automation="maxConsecutiveHours">
                    {{
                        registration.dispatchConf && registration.dispatchConf.maxConsecutiveHours
                            ? registration.dispatchConf.maxConsecutiveHours
                            : 0
                    }}
                </mat-label>
            </div>

            <div class="form-entry">
                <!--- Dispatch Config / Max Hours Per Season --->
                <mat-label class="info-title">{{ 'registration.max_hours_per_season' | translate }}:</mat-label>
                <mat-label class="info-value" data-automation="maxHoursPerSeason">
                    {{
                        registration.dispatchConf && registration.dispatchConf.maxHoursPerSeason
                            ? registration.dispatchConf.maxHoursPerSeason
                            : 0
                    }}
                </mat-label>
            </div>

            <div class="form-entry">
                <!--- Dispatch Config / Estimate Performance--->
                <mat-label class="info-title">{{ 'registration.estimate_performance' | translate }}:</mat-label>
                <mat-label class="info-value" data-automation="estimatePerformance">
                    {{
                        (registration.dispatchConf && registration.dispatchConf.estimatePerformanceInd
                            ? 'registration.true'
                            : 'registration.false'
                        ) | translate
                    }}
                </mat-label>
            </div>

            <div class="form-entry">
                <!--- Max Hours Per Day --->
                <mat-label class="info-title">{{ 'registration.max_hours_per_day' | translate }}:</mat-label>
                <mat-label class="info-value" data-automation="maxHoursPerDay">
                    {{ registration.maxHoursPerDay ? registration.maxHoursPerDay : 0 }}
                </mat-label>
            </div>

            <div class="form-entry label-info">
                <!--- Dispatch Config / Minutes between events --->
                <mat-label class="info-title" data-automation="minutesBetweenEvents">
                    {{ 'registration.minutes_between_events' | translate }}:
                    <mat-icon
                        svgIcon="info"
                        data-automation="minutesBetweenEventsIcon"
                        class="tooltip-icon"
                        [matTooltipPosition]="'below'"
                        matTooltip="{{ 'registration.tooltip.minutes_between_events' | translate }}"
                    ></mat-icon>
                </mat-label>
                <mat-label class="info-value" data-automation="minutesBetweenEventsValue">
                    {{
                        registration.dispatchConf && registration.dispatchConf.minutesBetweenEvents
                            ? registration.dispatchConf.minutesBetweenEvents
                            : 0
                    }}
                </mat-label>
            </div>

            <div class="form-entry">
                <!--- Customer Read Only --->
                <mat-label class="info-title">{{ 'registration.customer_read_only' | translate }}:</mat-label>
                <mat-label class="info-value" data-automation="customerReadOnly">
                    {{ (registration.customerReadOnly ? 'registration.true' : 'registration.false') | translate }}
                </mat-label>
            </div>

            <div class="form-entry label-info">
                <!--- Min Event Duration --->
                <mat-label class="info-title">
                    {{ 'registration.min_event_duration' | translate }}:
                    <mat-icon
                        svgIcon="info"
                        data-automation="minEventDurationIcon"
                        class="tooltip-icon"
                        [matTooltipPosition]="'below'"
                        matTooltip="{{ 'registration.tooltip.min_event_duration' | translate }}"
                    ></mat-icon>
                </mat-label>
                <mat-label class="info-value" data-automation="minEventDuration">
                    {{ registration.minEventDuration ? registration.minEventDuration : 0 }}
                </mat-label>
            </div>

            <div class="form-entry label-info">
                <!--- Max Event Duration --->
                <mat-label class="info-title">
                    {{ 'registration.max_event_duration' | translate }}:
                    <mat-icon
                        svgIcon="info"
                        data-automation="maxEventDurationIcon"
                        class="tooltip-icon"
                        [matTooltipPosition]="'below'"
                        matTooltip="{{ 'registration.tooltip.max_event_duration' | translate }}"
                    ></mat-icon>
                </mat-label>
                <mat-label class="info-value" data-automation="maxEventDuration">
                    {{ registration.maxEventDuration ? registration.maxEventDuration : 0 }}
                </mat-label>
            </div>

            <div class="form-entry">
                <!--- Locked Out --->
                <mat-label class="info-title">{{ 'registration.locked_out' | translate }}:</mat-label>
                <mat-label class="info-value" data-automation="lockedOut">
                    {{ (registration.lockedOut ? 'registration.true' : 'registration.false') | translate }}
                </mat-label>
            </div>

            <div class="form-entry">
                <!---- Registration Type --->
                <mat-label class="info-title">{{ 'registration.registration_type' | translate }}:</mat-label>
                <mat-label class="info-value" data-automation="registrationType">
                    {{ getRegistrationTypeDisplayLabel() }}</mat-label
                >
            </div>

            <div class="form-entry">
                <!--- preauthorized --->
                <mat-label class="info-title">{{ 'registration.preauthorized' | translate }}:</mat-label>
                <mat-label class="info-value" data-automation="preauthorized">
                    {{ (registration.preauthorized ? 'registration.true' : 'registration.false') | translate }}
                </mat-label>
            </div>

            <div class="form-entry label-info">
                <!--- Drop to kW --->
                <mat-label class="info-title"
                    >{{ 'registration.drop_to_value' | translate }}:
                    <mat-icon
                        svgIcon="info"
                        data-automation="dropToValueIcon"
                        class="tooltip-icon"
                        [matTooltipPosition]="'below'"
                        matTooltip="{{ 'registration.tooltip.drop_to_value' | translate }}"
                    ></mat-icon>
                </mat-label>

                <mat-label class="info-value" data-automation="dropToValue">
                    {{ registration.dropToValue ? registration.dropToValue : 0 }}
                </mat-label>
            </div>

            <!--- Ready to Test --->
            <div class="form-entry">
                <mat-label class="info-title">{{ 'registration.ready_to_test' | translate }}:</mat-label>
                <mat-label class="info-value" data-automation="readyToTest">
                    {{ (registration.readyToTest ? 'registration.true' : 'registration.false') | translate }}
                </mat-label>
            </div>

            <div class="form-entry label-info">
                <!--- Dispatch Config / Ramp Down Rate --->
                <mat-label class="info-title"
                    >{{ 'registration.ramp_down_rate' | translate }}:
                    <mat-icon
                        svgIcon="info"
                        data-automation="rampDownRateIcon"
                        class="tooltip-icon"
                        [matTooltipPosition]="'below'"
                        matTooltip="{{ 'registration.tooltip.ramp_down_rate' | translate }}"
                    ></mat-icon>
                </mat-label>

                <mat-label class="info-value" data-automation="rampDownRate">
                    {{
                        registration.dispatchConf && registration.dispatchConf.rampDownRate
                            ? registration.dispatchConf.rampDownRate
                            : 0
                    }}
                </mat-label>
            </div>

            <div class="form-entry label-info">
                <!--- Dispatch Config / Ramp Up Rate --->
                <mat-label class="info-title"
                    >{{ 'registration.ramp_up_rate' | translate }}:
                    <mat-icon
                        svgIcon="info"
                        data-automation="rampUpRateIcon"
                        class="tooltip-icon"
                        [matTooltipPosition]="'below'"
                        matTooltip="{{ 'registration.tooltip.ramp_up_rate' | translate }}"
                    ></mat-icon>
                </mat-label>

                <mat-label class="info-value" data-automation="rampUpRate">
                    {{
                        registration.dispatchConf && registration.dispatchConf.rampUpRate
                            ? registration.dispatchConf.rampUpRate
                            : 0
                    }}
                </mat-label>
            </div>

            <!--- static_baseline_value --->
            <div class="form-entry label-info">
                <mat-label class="info-title">{{ 'registration.baseline_value' | translate }}:</mat-label>
                <mat-label class="info-value" data-automation="baselineValueView">
                    {{ registration.baselineValue }}
                </mat-label>
            </div>

            <!--- loa_status --->
            <div class="form-entry label-info">
                <mat-label class="info-title">{{ 'registration.loa_status' | translate }}:</mat-label>
                <mat-label class="info-value" data-automation="loaStatus">
                    {{ getLOAStatusForRegistration()?.displayLabel }}
                </mat-label>
            </div>

            <!--- Energy Reduction Plan --->
            <div class="form-entry label-info">
                <mat-label class="info-title">{{ 'registration.energy_reduction_plan' | translate }}:</mat-label>
                <mat-label
                    class="wrapped"
                    data-automation="energyReductionPlan"
                    [matTooltip]="registration.energyReductionPlan"
                >
                    {{ registration.energyReductionPlan }}
                    <mat-icon
                        svgIcon="info"
                        data-automation="energyReductionPlanIcon"
                        class="tooltip-icon"
                        [matTooltipPosition]="'below'"
                        matTooltip="{{ 'registration.tooltip.energy_reduction_plan' | translate }}"
                    ></mat-icon>
                </mat-label>
            </div>

            <div class="form-entry label-info">
                <mat-label class="info-title">{{ 'registration.project_manager_name' | translate }}:</mat-label>
                <mat-label class="info-value" data-automation="projectManagerName">
                    {{ registration.projectManagerName }}
                </mat-label>
            </div>

            <div class="form-entry label-info">
                <mat-label class="info-title">{{ 'registration.project_manager_id' | translate }}:</mat-label>
                <mat-label class="info-value" data-automation="projectManagerId">
                    {{ registration.projectManagerId }}
                </mat-label>
            </div>

            <div class="form-entry label-info">
                <mat-label class="info-title">{{ 'registration.account_manager_name' | translate }}:</mat-label>
                <mat-label class="info-value" data-automation="accountManagerName">
                    {{ registration.accountManagerName }}
                </mat-label>
            </div>

            <div class="form-entry label-info">
                <mat-label class="info-title">{{ 'registration.account_manager_id' | translate }}:</mat-label>
                <mat-label class="info-value" data-automation="accountManagerId">
                    {{ registration.accountManagerId }}
                </mat-label>
            </div>

            <!-- Telemetry Points -->
            <div class="telemetry-points-header form-header">
                <h2 data-automation="points-header">
                    {{ 'telemetry_point.title' | translate }}
                </h2>
                <mat-icon
                    data-automation="add-point"
                    class="add-point"
                    *ngIf="(isEditMode || isCreateMode) && !isRetired"
                    svgIcon="plusButton"
                    (click)="addDynamicPoint()"
                ></mat-icon>
            </div>

            <div class="points-content">
                <div class="empty-points" *ngIf="!hasPoints">
                    <div><mat-icon svgIcon="noData"></mat-icon></div>
                    <h6>{{ 'telemetry_point.empty_title' | translate }}</h6>
                    <div data-automation="empty-points" class="empty-filler">
                        {{ 'telemetry_point.empty' | translate }}
                    </div>
                </div>

                <ng-container *ngIf="hasPoints && appData">
                    <ng-container *ngFor="let point of points; let i = index; let c = count">
                        <app-telemetry-point
                            *ngIf="!point.deleted"
                            [mode]="mode"
                            [point]="point"
                            [appData]="appData"
                            [userLocale]="userLocale"
                            [selectedChannels]="selectedChannels"
                            [registrationName]="registrationName"
                            [siteId]="registration.siteId"
                            [reportingIntervals]="reportingIntervals"
                            [dataProviders]="dataProviders"
                            [index]="i"
                            [count]="c"
                            [multiLocaleConfig]="multiLocaleConfig"
                            [registration]="registration"
                            [retired]="isRetired"
                            (addDynamicPoint)="addDynamicPoint()"
                            (deleteDynamicPoint)="deleteDynamicPoint($event)"
                            (updateSelectedChannels)="updateSelectedChannels()"
                            (updateSourceIds)="updateSourceIds()"
                        >
                        </app-telemetry-point>
                    </ng-container>
                </ng-container>
            </div>

            <!-- Baseline Points -->
            <div class="baseline-points-header form-header">
                <h2 data-automation="baseline-points-header">
                    {{ 'baseline_point.title' | translate }}
                </h2>
                <mat-icon
                    data-automation="add-baseline-point"
                    class="add-baseline-point"
                    *ngIf="(isEditMode || isCreateMode) && !isRetired"
                    svgIcon="plusButton"
                    (click)="addDynamicBaselinePoint()"
                ></mat-icon>
            </div>
            <div class="baseline-points-content">
                <div class="empty-baseline-points" *ngIf="!hasBaselinePoints">
                    <div><mat-icon svgIcon="noData"></mat-icon></div>
                    <h6>{{ 'baseline_point.empty_title' | translate }}</h6>
                    <div data-automation="empty-baseline-points" class="empty-filler">
                        {{ 'baseline_point.empty' | translate }}
                    </div>
                </div>

                <ng-container *ngIf="hasBaselinePoints && appData">
                    <ng-container
                        *ngFor="
                            let baselinePoint of baselinePoints;
                            let indexBaselinePoint = index;
                            let countBaselinePoint = count
                        "
                    >
                        <app-baseline-point
                            *ngIf="!baselinePoint.deleted"
                            [mode]="mode"
                            [baselinePoint]="baselinePoint"
                            [appData]="appData"
                            [userLocale]="userLocale"
                            [selectedBaselineChannels]="selectedBaselineChannels"
                            [registrationName]="registrationName"
                            [siteId]="registration.siteId"
                            [reportingIntervals]="reportingIntervals"
                            [dataProviders]="dataProviders"
                            [index]="indexBaselinePoint"
                            [count]="countBaselinePoint"
                            [multiLocaleConfig]="multiLocaleConfig"
                            [registration]="registration"
                            [retired]="isRetired"
                            [baselineDefinitions]="baselineDefinitions"
                            (updateSelectedBaselinePointChannels)="updateSelectedBaselinePointChannels()"
                            (addDynamicBaselinePoint)="addDynamicBaselinePoint()"
                            (deleteDynamicBaselinePoint)="deleteDynamicBaselinePoint($event)"
                        >
                        </app-baseline-point>
                    </ng-container>
                </ng-container>
            </div>

            <!-- Transform rules -->
            <app-transform-rules
                [rules]="transformRules"
                [availabilityPoints]="availabilityPoints"
                [isEditMode]="isEditMode"
                [isCreateMode]="isCreateMode"
            ></app-transform-rules>
        </div>
    </ng-container>
</div>
